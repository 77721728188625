import { Injectable } from '@angular/core';

export interface NavigationItem {
    id: string;
    title: string;
    type: 'item' | 'collapse' | 'group';
    translate?: string;
    icon?: string;
    hidden?: boolean;
    url?: string;
    classes?: string;
    exactMatch?: boolean;
    external?: boolean;
    target?: boolean;
    breadcrumbs?: boolean;
    function?: any;
    badge?: {
        title?: string;
        type?: string;
    };
    children?: Navigation[];
}

export interface Navigation extends NavigationItem {
    children?: NavigationItem[];
}

const NavigationItems = [
    {
        id: 'account-header',
        title: 'Accounts',
        type: 'group',
        icon: 'feather icon-layers',
        children: [
            {
                id: 'account-child',
                title: 'Accounts',
                type: 'item',
                url: 'accounts',
                qp:'',
                icon: 'feather icon-align-justify'
            }
        ]
    },
    {
        id: 'user-header',
        title: 'Users',
        type: 'group',
        icon: 'feather icon-layers',
        children: [
            {
                id: 'user-child',
                title: 'Users',
                type: 'item',
                url: 'users',
                qp:'',
                icon: 'feather icon-users'
            }
        ]
    },
    // {
    //     id: 'user-header',
    //     title: 'Reports',
    //     type: 'group',
    //     icon: 'feather icon-layers',
    //     children: [
    //         {
    //             id: 'user-child',
    //             title: 'Web Usage Analytics',
    //             type: 'item',
    //             url: 'reports',
    //             icon: 'feather icon-bar-chart',
    //             qp: 'embed/dashboards/2841',
    //             isReport: true
    //         },
    //         {
    //             id: 'user-child',
    //             title: 'Total Touch points',
    //             type: 'item',
    //             url: 'reports',
    //             icon: 'feather icon-bar-chart',
    //             qp: 'embed/dashboards/2756',
    //             isReport: true
    //         },
    //         {
    //             id: 'user-child',
    //             title: 'Overview Analytics - Email & Users',
    //             type: 'item',
    //             url: 'reports',
    //             icon: 'feather icon-bar-chart',
    //             qp: 'embed/dashboards/2840',
    //             isReport: true
    //         },
    //         {
    //             id: 'user-child',
    //             title: 'Health check ',
    //             type: 'item',
    //             url: 'reports',
    //             icon: 'feather icon-bar-chart',
    //             qp: 'embed/dashboards/2939',
    //             isReport: true
    //         }
    //     ]
    // },
];

@Injectable({
    providedIn: 'root'
})
export class NavigationItem {
    public get() {
        return NavigationItems;
    }
}
