import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { DataApiService } from '../../core/services';
import { AuthEventsData, UserData } from '../../shared/models';
import { UiModalService } from '../components/modal/ui-modal.service';
import { PartialAccountData } from './../models/account-data';
import { SubscriptionData } from './../models/subscription-data';
import { PartialUserData } from './../models/user-data';

const USERS_API = 'admin/users';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private dataApiService: DataApiService,
        private uiModalService: UiModalService) {}

    getUsers(): Observable<UserData[]> {
        return this.dataApiService.getData<UserData[]>(USERS_API);
    }

    search(searchTerm: string): Observable<UserData[]> {
        if (!searchTerm) {
            return of([]);
        }

        const resEndPoint = `${USERS_API}/search?email=${searchTerm}`;
        return this.dataApiService.getData<UserData[]>(resEndPoint);
    }

    getUserByName(name: string): Observable<UserData> {
        return this.dataApiService.getData<UserData>(`${USERS_API}/username/${name}`);
    }

    createUser(user: PartialUserData): Observable<PartialUserData> {
        return this.dataApiService.postData<PartialUserData>(`${USERS_API}`, user);
    }

    updateUser(user: PartialUserData): Observable<PartialUserData> {
        return this.dataApiService.patchData(`${USERS_API}`, user);
    }

    deleteUser(userId) {
        return this.dataApiService.deleteData(`${USERS_API}/${userId}`);
    }

    getSubscriptions(userId: string, accountId: string): Observable<SubscriptionData[]> {
        const url = `accounts/${accountId}/users/${userId}/subscriptions`;
        return this.dataApiService.getData<SubscriptionData[]>(url);
    }

    addUserSubscription(accountId: string, subId: string, userId: string) {
        const url = `accounts/${accountId}/subscriptions/${subId}/users/${userId}`;
        return this.dataApiService.postData(url, {});
    }

    removeUserSubscription(accountId: string, subId: string, userId: string) {
        const endResourceApi = `accounts/${accountId}/subscriptions/${subId}/users/${userId}`;
        return this.dataApiService.deleteData<UserData>(endResourceApi);
    }

    disableUser(userId: string) {
        return this.dataApiService.postData(`${USERS_API}/${userId}/disable`, {});
    }

    enableUser(userId: string) {
        return this.dataApiService.postData(`${USERS_API}/${userId}/enable`, {});
    }

    resetUserPassword(userId: string) {
        return this.dataApiService.postData(`${USERS_API}/${userId}/resetpassword`, {});
    }

    resendConfirmationCode(userId: string) {
        return this.dataApiService.postData(`auth/resendconfirmationcode`, { email: userId });
    }

    confirmUser(userId: string) {
        return this.dataApiService.postData(`${USERS_API}/${userId}/adminconfirmsignup`, {});
    }

    resendTemporaryPassword(username: string) {
        return this.dataApiService.postData(`admin/resendtemppassword`, { userName: username });
    }

    verifyUserEmail(userId: string) {
        return this.dataApiService.postData(`${USERS_API}/${userId}/enable`, {});
    }

    getUserAuthEvents(userId: string): Observable<AuthEventsData> {
        return this.dataApiService.getData(`${USERS_API}/${userId}/authevents`);
    }

    openAddEditUserModal(content: any, account: PartialAccountData, user?: UserData): NgbModalRef {
        const modalRef = this.uiModalService.openModal(content, { size: 'lg' });
        modalRef.componentInstance.account = account;
        modalRef.componentInstance.user = user;
        return modalRef;
    }
}
