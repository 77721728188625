import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    private static get s4(): string {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }

    static isEmpty(value: any): boolean {
        if (!value || value === '') {
            return true;
        }

        if (Array.isArray(value)) {
            return value.length === 0;
        }

        return Object.keys(value).length === 0;
    }

    static flattenArrayObjects(data: any[]): any[] {
        if (this.isEmpty(data)) {
            return[];
        }

        const dataArr = Array.isArray(data) ? data : [data];
        return dataArr.map(item => this.flattenObject(item));
    }

    static flattenObject(obj: any): any {
		const flattened = {}
		const flattenObj = (obj) => {
			Object.keys(obj).forEach((key) => {
				if (typeof obj[key] === 'object' && obj[key] !== null) {
                    flattenObj(obj[key]);
				} else {
					flattened[key] = obj[key];
				}
			})
			return flattened;
		}

		return flattenObj(obj);
	}

    static generateGuid(): string {
        return `${this.s4}${this.s4}${this.s4}${this.s4}${this.s4}${this.s4}${this.s4}${this.s4}`;
    }

    static deepCopy<T>(items: any): T {
        return JSON.parse(JSON.stringify(items));
    }
}
