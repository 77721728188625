import { ListItem } from './../../models/list-item';

type OptionalListItem = Partial<ListItem>;

function autoImplement<T>(): new () => T {
    return class { } as any;
}

export class DropdownListItem extends autoImplement<OptionalListItem>() {
    class?: string;
    visible ?= true;
    disabled ?= false;
    isDivider ?= false;
    clickFn?: () => void;
}
