import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PartialAccountData, PartialUserData } from './../../../models';
const Locations = require('src/fake-data/locations.json');

@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {

    @Input() isAddUser = false;
    @Input() isReadonly = false;
    @Input() user: PartialUserData;
    @Input() account: PartialAccountData;

    form: UntypedFormGroup;
    locations: {name: string}[] = [];

    constructor(private formBuilder: UntypedFormBuilder) { }

    get controls(): {[key: string]: AbstractControl} {
        return this.form.controls;
    }

    get isValid(): boolean {
        return this.form.valid;
    }

    ngOnInit(): void {
        this.form = this.createFormModel(this.account);
        this.locations = Locations;
        if (this.isReadonly) {
            this.setDisabled(this.controls);
        }
    }

    private createFormModel(acc: PartialAccountData): UntypedFormGroup {
        return this.formBuilder.group({
            accountId: [acc?.accountId],
            accountName: [acc?.accountName],
            givenName: ['', [Validators.required]],
            familyName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            password: [''],
            jobTitle: [''],
            jobLocation: [''],
            linkedInLink: [''],
            notes: [''],
            // sfId: ['', [Validators.maxLength(18), Validators.pattern(/^[a-zA-Z0-9]{18}/)]],
            sfId: ['', [Validators.minLength(18), Validators.maxLength(50)]],
            phoneNumber: ['', [Validators.pattern(/\+?\d+/)]],
            suppressWelcomeEmail: false
        });
    }

    private setDisabled(controls: {[key: string]: AbstractControl}): void {
        Object.keys(controls).forEach(key => {
            this.form.get(key).disable();
        });
    }

    getFormValues(): PartialUserData {
        const user: PartialUserData = {
            givenName: this.controls.givenName.value,
            familyName: this.controls.familyName.value,
            email: this.controls.email.value,
            userName: this.user?.userName ?? this.controls.email.value,
            jobTitle: this.controls.jobTitle.value,
            jobLocation: this.controls.jobLocation.value,
            phoneNumber: this.getPhoneNumber(this.controls),
            sfId: this.controls.sfId.value,
            linkedInLink: this.addhttps(this.controls.linkedInLink.value),
            notes: this.controls.notes.value,
        }

        if (this.isAddUser) {
            user.accountId = this.controls.accountId.value;
            user.tempPassword = this.controls.password.value;
            user.suppressWelcomeEmail = this.controls.suppressWelcomeEmail.value;
            delete user.userName;
        }
        return this.convertEmptyStringsIntoUndefined(user);
    }

    private addhttps(url) {
        if (url && !/^(?:f|ht)tps?\:\/\//.test(url)) {
            url = "https://" + url;
        }
        return url;
    }

    private getPhoneNumber(ctrl: {[key: string]: AbstractControl}): string | undefined {
        return ctrl.phoneNumber.value ? `+${ctrl.phoneNumber.value.replace(/\+/g, '')}` : undefined;
    }

    private convertEmptyStringsIntoUndefined<T>(data: T): T {
        Object.keys(data).map((key, value) => {
            data[key] = data[key] ? data[key] : undefined;
        })
        return data;
    }
}
