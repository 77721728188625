import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Auth } from 'aws-amplify';
import { ToastrService } from 'ngx-toastr';
import { from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GlobalsService } from './globals.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {

    ipAddress: string;
    checkingIP: boolean;
    gettingIP = false;
    validIP: boolean;
    ipUser: any;
    loggedIn: boolean;
    role: string;

    constructor(private toastrService: ToastrService,
        private globalService: GlobalsService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (window.location.hostname == 'localhost') {
            this.role = 'admin';
            this.globalService.isSuperUser = true;
            this.globalService.userid = '1542d324-9179-4cc6-bf99-9bad32cc108a';
            this.globalService.username = this.globalService.isSuperUser ? 'Super User' : 'Admin User'
            this.loggedIn = true;
            return of(true)
        }

        return from(Auth.currentAuthenticatedUser({ bypassCache: true }))
            .pipe(
                map(result => {
                        let authGroup = result.signInUserSession.accessToken.payload["cognito:groups"]
                        if (authGroup && authGroup.length > 0) {
                            if (authGroup.includes(environment.aws.adminGroup)) {
                                this.role = 'admin';
                                if (authGroup.includes(environment.aws.superUserGroup)) {
                                    this.globalService.isSuperUser = true;
                                }                                

                                if (next.data.allowedRoles.includes(this.role)) {
                                    return true;
                                } else {
                                    // this.toastrService.warning('You are not authorized to view this page.');
                                    // this.router.navigateByUrl('/not-authorized');
                                }
                            }

                        } else {
                            this.toastrService.warning('You are not logged in.');
                            window.location.assign(`${environment.portalUrl}/login`);
                            return false;
                        }
                        this.globalService.username =`${result.attributes.given_name} ${result.attributes.family_name}`;
                        this.globalService.userid = `${result.attributes.sub}`
                    }),
                catchError(error => {
                    this.toastrService.warning('You are not authorized to view this page.')
                    window.location.assign(`${environment.portalUrl}/login`);
                    return of(false);
                })
            )
    }
}
