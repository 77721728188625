import { Observable } from "rxjs";

export class AutocompleteOptions<T> {
    historyIdentifier?: string;
    searchKeyword: string;
    placeholder ?= 'Search...';
    groupHeaderField?: string;
    clearInputOnSelection ?= false;
    minQueryLength ?= 3;
    templateFields: AutocompleteItemTemplateField[];
    searchFn?: (term: string) => Observable<T[]>;
}

export interface AutocompleteItemTemplateField {
    fieldName: string;
    colName: string;
    class?: string;
}