import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: [
        './spinner.component.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnInit, OnDestroy {

    @Input() message = '';
    @Input() isSpinnerVisible = true;

    private readonly onDestroy = new Subject<void>();

    constructor(@Inject(DOCUMENT) private document: Document,
        private router: Router) {}

    ngOnInit(): void {
        this.router.events
            .pipe(takeUntil(this.onDestroy))
            .subscribe(event =>
                this.setSpinnerVisible(event),
                () => this.isSpinnerVisible = false);
    }

    private setSpinnerVisible(event: any) {
        if (event instanceof NavigationStart) {
            this.isSpinnerVisible = true;
        } else if ( event instanceof NavigationEnd || event instanceof NavigationCancel
                || event instanceof NavigationError) {
            this.isSpinnerVisible = false;
        }
        return event;
    }

    ngOnDestroy(): void {
        this.isSpinnerVisible = false;
        this.onDestroy.next();
    }
}