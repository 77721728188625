import { environment } from 'src/environments/environment';
import { Component, OnInit, ChangeDetectionStrategy, Renderer2, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { Subject, Observable, of, concat } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError } from 'rxjs/operators'
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery';
import * as querystring from 'query-string';
import * as CryptoJS from 'crypto-js';


@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent {
  listenerFn: () => void;
  // lookerUrl = environment.lookerApi.url;
  iFrame: HTMLIFrameElement = $('#looker')[0];
  url: any;
  goodUrl: SafeUrl;
  dashboardIsReady: boolean = false;
  session: any
  email: any;
  fullName: any = "Embed User";
  dashboardLoaded: boolean = false;
  lookerGroupIds: any;
  accountName: any = "Embed";

  workspaceId: string = 'a5fe8333-1aaa-4923-9a6d-4d2a2baed747'
  reportId1: string = '84512c32-9a3d-4271-8b38-5835eb8fae0f'

  constructor(
  private router: Router,
  private route: ActivatedRoute,
  private _sanitizer: DomSanitizer,
  private cookieService: CookieService,
  private renderer: Renderer2) {
}

ngOnInit() {
  // this.watchLooker()
  this.getCookies(environment.amplify.Auth.userPoolWebClientId, 'userData')
}

private getCookies(userPoolWebClientId: string, key: string) {
  const allCookies = this.cookieService.getAll();

  for (let cookie in allCookies) {
      if(cookie.includes(key) && cookie.includes(userPoolWebClientId)) {
        
        this.dashboardIsReady = true;
        // this.watchLooker()

          return JSON.parse(allCookies[cookie]);
      }
  }
}


//   watchLooker() {

//           this.nonce(10);
//           this.url = this.createUrl();
//           this.goodUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.url);


//       this.listenerFn = this.renderer.listen(window, 'message', (event) => {
//         if (event.source === $('#looker')[0].contentWindow) {
//           let lookerIframe = $('#looker')[0];
//           let data = JSON.parse(event.data)
//           if (data.type == 'dashboard:filters:changed' && this.dashboardLoaded) {
//             // this.selectedMarket = data.dashboard.dashboard_filters.Country
//             // this.update(this.selectedMarket)
//           }
//           if (data.type == 'page:properties:changed') {
//             this.dashboardLoaded === true;
//             lookerIframe.style.height = (data.height > 1000 ? data.height : '1000') + 'px';
//           }
//           if (data.type == 'dashboard:run:start' || data.type == 'explore:state:changed') {
//             // $('#safari-warning').hide()
//           }
//         }
//       })
//     }

//     nonce(len) {
//    let text = "";
//    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

//    for (var i = 0; i < len; i++)
//      text += possible.charAt(Math.floor(Math.random() * possible.length));
//    return text;
//  }

//  forceUnicodeEncoding(string) {
//    return decodeURIComponent(encodeURIComponent(string));
//  }

//  created_signed_embed_url(options) {
//    // looker options
//    let secret = options.secret;
//    let host = options.host;

//    // user options
//    let json_external_user_id = JSON.stringify(options.external_user_id);
//    let json_first_name = JSON.stringify(options.first_name);
//    let json_last_name = JSON.stringify(options.last_name);
//    let json_permissions = JSON.stringify(options.permissions);
//    let json_models = JSON.stringify(options.models);
//    let json_group_ids = JSON.stringify(options.group_ids);
//    let json_external_group_id = JSON.stringify(options.external_group_id || "");
//    let json_user_attributes = JSON.stringify(options.user_attributes || {});
//    let json_access_filters = JSON.stringify(options.access_filters);

//    // url/session specific options
//    let embed_path = '/login/embed/' + encodeURIComponent(options.embed_url);
//    let json_session_length = JSON.stringify(options.session_length);
//    let json_force_logout_login = JSON.stringify(options.force_logout_login);

//    // computed options
//    let json_time = JSON.stringify(Math.floor((new Date()).getTime() / 1000));
//    let json_nonce = JSON.stringify(this.nonce(16));

//    // compute signature
//    let string_to_sign = "";
//    string_to_sign += host + "\n";
//    string_to_sign += embed_path + "\n";
//    string_to_sign += json_nonce + "\n";
//    string_to_sign += json_time + "\n";
//    string_to_sign += json_session_length + "\n";
//    string_to_sign += json_external_user_id + "\n";
//    string_to_sign += json_permissions + "\n";
//    string_to_sign += json_models + "\n";
//    string_to_sign += json_group_ids + "\n";
//    // string_to_sign += json_external_group_id + "\n";
//    string_to_sign += json_access_filters;

//    let HASHED = CryptoJS.HmacSHA1(string_to_sign, secret);
//    let signature = CryptoJS.enc.Base64.stringify(HASHED);


//    // construct query string
//    let query_params = {
//      nonce: json_nonce,
//      time: json_time,
//      external_user_id: json_external_user_id,
//      session_length: json_session_length,
//      permissions: json_permissions,
//      models: json_models,
//      access_filters: json_access_filters,
//      group_ids: json_group_ids,
//      force_logout_login: json_force_logout_login,
//      signature: signature
//    };

//    var query_string = querystring.stringify(query_params);

//    this.dashboardIsReady = true;

//    return host + embed_path + '?' + query_string;
//  }

//  createUrl() {
//    let query, embed, firstName;

//    let lastName = '';
//    let queryString = '';

//    let embed_domain = environment.embed_domain;


//    embed = `/embed/dashboards-next/1632?embed_domain=${embed_domain}`

//    let url_data = {
//      host: 'looker.flywheeldigital.com',
//      secret: '522bed238788c509374c9452c7434bf819806c121c9524799fd88fa931f8b48c',
//      external_user_id: "12345",
//      group_ids: [9, 515],
//      permissions: [],
//      models: ['prng_ga'],
//      first_Name: "ERI",
//      last_name: "AdminUser",
//      user_attributes: { "email": "eriadmin@tech.ascentialedge.com", "name": "ERI AdminUser" },
//      access_filters: {},
//      session_length: 2592000,
//      embed_url: embed,
//      force_logout_login: true,
//    };

//    let url = this.created_signed_embed_url(url_data);
//    this.dashboardIsReady = true;

//    return "https://" + url;
//  }
}
