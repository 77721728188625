import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GlobalsService } from '../services/globals.service';
import { TokenStorageService } from '../services/token-storage.service';

const UNAUTORIZED_CODE = 401;

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

    constructor (private tokenStorageSrv: TokenStorageService,private globalService: GlobalsService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const jwtToken = this.getToken();
        request = request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + jwtToken,
                'content-type': 'application/json'
            }
        });

        return next.handle(request)
            .pipe(
                catchError(error => {
                    if (this.isAuthorized(error)) {
                        return null;
                    }
                    return throwError(error);
                })
            );
    }

    private getToken() {
        const header = {
            "alg": "HS256",
            "typ": "JWT"
        };

        const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
        const encodedHeader = this.base64url(stringifiedHeader);
        let now = Math.floor(Date.now() / 1000); // Current timestamp in seconds
        let expiresIn = 30; //30 sec is mandatory for the web application
    
        const data = {
            "client_id": environment.clientId,
            "client_name": environment.clientName,
            "api_key": environment.apiKey,
            "user_id": this.globalService.userid,
            "exp":  now + expiresIn // Expiration timestamp
        }
        const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
        const encodedData = this.base64url(stringifiedData);
        const token = encodedHeader + "." + encodedData;
        const signature = CryptoJS.HmacSHA256(token, environment.secretKey);
        return token + "." + this.base64url(signature);
    }

    private isAuthorized(error: HttpErrorResponse): boolean {
        return error instanceof HttpErrorResponse && error.status === UNAUTORIZED_CODE;
    }

    private base64url(source) {
        let encodedSource = CryptoJS.enc.Base64.stringify(source);
        encodedSource = encodedSource.replace(/=+$/, '');
        encodedSource = encodedSource.replace(/\+/g, '-');
        encodedSource = encodedSource.replace(/\//g, '_');
        return encodedSource;
    }
}
