import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UtilService } from './../../../../../core/services/util.service';

@Component({
    selector: 'app-checkbox-column',
    templateUrl: './checkbox-column.component.html',
    styleUrls: ['./checkbox-column.component.scss']
})
export class CheckboxColumnComponent implements OnChanges {

    @Input() property: string;
    @Input() dataItem: any;

    @Output() itemChecked = new EventEmitter<any>();
    @Output() itemUnchecked = new EventEmitter<any>();

    isChecked = false;

    ngOnChanges(changes: SimpleChanges): void {
        const item = changes.dataItem
            ? changes.dataItem.currentValue
            : null;
        if (item) {
            item.checkboxId = this.createCheckboxId();
            this.isChecked = item[this.property];
            this.dataItem = item;
        }
    }

    private createCheckboxId(): string {
        return 'checkbox' + UtilService.generateGuid();
    }

    onCheckboxChange(dataItem: any): void {
        this.isChecked = !this.isChecked;
        if (this.isChecked) {
            this.itemChecked.emit(dataItem);
        } else {
            this.itemUnchecked.emit(dataItem);
        }
    }
}
