import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { of, Subject } from 'rxjs';
import { finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UtilService } from './../../../core/services/util.service';
import { AutocompleteOptions } from './autocomplete-options';

@Component({
	selector: 'app-autocomplete',
	templateUrl: './autocomplete.component.html',
	styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent<T> implements OnInit, OnDestroy {

	@Input() options: AutocompleteOptions<T>;
	@Input() disabled: boolean;

	@Output() selected = new EventEmitter<any>();
	@Output() inputFocused = new EventEmitter();

	data: T[];
	itemTemplateClass: string;
	isLoadingResult = false;

	@ViewChild('auto') private auto: any;

	private readonly onDestroy = new Subject<void>();

	ngOnInit(): void {
		this.options = this.extendDefaultOptions(this.options);

		const noOfFields = this.options.templateFields.length || 1;
		this.itemTemplateClass = 'col-' + 12/noOfFields;
	}

	private extendDefaultOptions(options: AutocompleteOptions<T>): AutocompleteOptions<T> {
        return {...new AutocompleteOptions(), ...options};
    }

	onChangeSearch(searchTerm: string): void {
		if (!searchTerm) {
            this.data = [];
        }

		of(searchTerm)
			.pipe(
				takeUntil(this.onDestroy),
				tap(_ => {
					this.isLoadingResult = true;
					this.data = [];
				}),
				switchMap(this.options.searchFn),
				finalize(() => this.isLoadingResult = false)
			)
			.subscribe(data => {
				this.data = UtilService.flattenArrayObjects(data);
			});
	}

	selectEvent(item: any): void {
		this.selected.emit(item);
		if (this.options.clearInputOnSelection) {
			this.clearInput();
		}
	}

	private clearInput(): void {
		this.auto.clear();
	}

	onFocused(event: any): void {
		this.inputFocused.emit(event);
	}

	onInputCleared = () => {
		this.auto.close();
	}

	ngOnDestroy(): void {
        this.onDestroy.next();
    }
}
