import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataTableColumn } from './models/data-table-column';

@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {

    @Input() columns: any[];
    @Input() dataRows: any[];
    @Input() paginationToken: string | any;
    @Input() rowHeight: string | number = '50';
    @Input() hideFooter = false;
    @Input() hideHeader = false;

    @Output() keyFieldClick = new EventEmitter<any>();
    @Output() itemChecked = new EventEmitter<any>();
    @Output() itemUnchecked = new EventEmitter<any>();
    @Output() getMoreData = new EventEmitter<string>();

    ngOnInit(): void {
        this.columns = this.columns
            ? this.columns.map(col => this.extendColsDefaultValues(col))
            : [];
    }

    private extendColsDefaultValues(col: DataTableColumn): DataTableColumn {
        return {...new DataTableColumn(), ...col};
    }

    loadMoreData(paginationToken: string): void {
        this.getMoreData.emit(paginationToken);
    }
}
