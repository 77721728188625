import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  lookerGroupIds: any = [];
  dataLoaded: boolean = false
  embedURL:string = ''
  hasAccess: boolean;
  params: string =''
  constructor(
    private router: Router,
    private route: ActivatedRoute,
) { 

    
  }
  
  ngOnInit(): void {
    
    this.route.queryParams.subscribe(params => {

      this.embedURL = params['url'];
      this.dataLoaded = true
      
    });
    
  }
 

}
