import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-header-content',
    templateUrl: './modal-header-content.component.html',
    styleUrls: ['./modal-header-content.component.scss']
})
export class ModalHeaderContentComponent {

    @Input() title: string;
    @Input() showCloseButton = true;

    constructor(private activeModal: NgbActiveModal) {}

    closeDialog() {
        this.activeModal.dismiss(false);
    }
}
