import { Component, EventEmitter, Input, OnInit, Output, Renderer2, OnChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import * as querystring from 'qs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-looker-embeds',
  templateUrl: './looker-embeds.component.html',
  styleUrls: ['./looker-embeds.component.css']
})
export class LookerEmbedsComponent implements OnInit {
  listenerFn: () => void;

  goodUrl: SafeUrl
  dashboardIsReady: Boolean = false
  accountName: string
  validLookerExploreGroupIds: any = [357,358];
  
  @Input() lookerGroupIds: any
  @Output() dashboardReadyEvent = new EventEmitter();
  @Input() embedURL: string = ''
  @Input() params: string = ''
  @Input() currency: string =''
  
  constructor(
    private _sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private router: Router,
    
  ) {
    
  }

  ngOnInit(): void {
    //Do not load this on init. 
    //  this.LoadLooker()
  }

  ngOnChanges() {

    if (this.embedURL && this.embedURL.toLowerCase().includes('explore') && !this.lookerGroupIds.some((id: any) => this.validLookerExploreGroupIds.includes(id))) {
      this.dashboardIsReady = false;
      
      
    } else {
      
      this.LoadLooker()
    }
    
  }
  
  LoadLooker() {
    
    
        let embed
        let embed_domain = environment.embed_domain;
        embed = `${this.embedURL}?embed_domain=${embed_domain}&${this.params}`
      console.log(embed)
        var url_data = {

          host: 'looker.flywheeldigital.com',
          secret: '522bed238788c509374c9452c7434bf819806c121c9524799fd88fa931f8b48c',
          external_user_id: "12345",
          group_ids: [9, 515],
          permissions: [],
          models: ['prng_ga'],
          first_Name: "ERI",
          last_name: "AdminUser",
          user_attributes: { "email": "eriadmin@tech.ascentialedge.com", "name": "ERI AdminUser" },
          access_filters: {},
          session_length: 2592000,
          embed_url: embed,
          force_logout_login: true,
        };

        var url = this.created_signed_embed_url(url_data);
        url = "https://" + url;
        this.goodUrl = this._sanitizer.bypassSecurityTrustResourceUrl(url)
        
        console.log(this.goodUrl)

        this.dashboardReadyEvent.emit()
        this.dashboardIsReady = true

        let activeComponent = this.router.routerState.root.snapshot.firstChild;

        this.listenerFn = this.renderer.listen(window, 'message', (event) => {

          if (event.origin === 'https://looker.flywheeldigital.com') {
            
            var eventData = JSON.parse(event.data)

            if (eventData) {
              //  console.log(eventData)
              if (eventData.type == 'page:properties:changed') {
                // console.log(eventData.height)
                var divElement = document.querySelector<HTMLDivElement>('#looker')
                var height = eventData.height < 100 ? 2000:eventData.height
                divElement!.style.height = `${height}px`
                divElement!.style.width = '100%'
              }
              if (eventData.type == 'explore:state:changed') {
                
                var divElement = document.querySelector<HTMLDivElement>('#looker')
                divElement!.style.height = `${eventData.height}px`
                divElement!.style.height = `1000px`
                divElement!.style.width = '100%'
                
              }
              
            }
          }
        })
        
      
    
  }


  // looker internal functions
  nonce(len: number) {
    let text = "";
    let possible = "0123456789109ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

    for (var i = 0; i < len; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }
  created_signed_embed_url(options: any) {
    // looker options
    let secret = options.secret;
    let host = options.host;

    // user options
    var json_external_user_id = JSON.stringify(options.external_user_id);
    var json_first_name = JSON.stringify(options.first_name);
    var json_last_name = JSON.stringify(options.last_name);
    var json_permissions = JSON.stringify(options.permissions);
    var json_models = JSON.stringify(options.models);
    var json_group_ids = JSON.stringify(options.group_ids);
    var json_external_group_id = JSON.stringify(options.external_group_id || "");
    var json_user_attributes = JSON.stringify(options.user_attributes || {});
    var json_access_filters = JSON.stringify(options.access_filters);

    // url/session specific options
    var embed_path = '/login/embed/' + encodeURIComponent(options.embed_url);
    var json_session_length = JSON.stringify(options.session_length);
    var json_force_logout_login = JSON.stringify(options.force_logout_login);

    // computed options
    //var json_time = options.time;
    var json_time = JSON.stringify(Math.floor((new Date()).getTime() / 1000));
    var json_nonce = JSON.stringify(this.nonce(30));

    // compute signature
    var string_to_sign = "";
    string_to_sign += host + "\n";
    string_to_sign += embed_path + "\n";
    string_to_sign += json_nonce + "\n";
    string_to_sign += json_time + "\n";
    string_to_sign += json_session_length + "\n";
    string_to_sign += json_external_user_id + "\n";
    string_to_sign += json_permissions + "\n";
    string_to_sign += json_models + "\n";
    string_to_sign += json_group_ids + "\n";
    string_to_sign += json_external_group_id + "\n";
    string_to_sign += json_user_attributes + "\n";
    string_to_sign += json_access_filters;

    let hashed = CryptoJS.HmacSHA1(string_to_sign, secret)

    let signature = CryptoJS.enc.Base64.stringify(hashed);

    // construct query string
    var query_params = {
      nonce: json_nonce,
      time: json_time,
      session_length: json_session_length,
      external_user_id: json_external_user_id,
      permissions: json_permissions,
      models: json_models,
      access_filters: json_access_filters,
      first_name: json_first_name,
      last_name: json_last_name,
      group_ids: json_group_ids,
      external_group_id: json_external_group_id,
      user_attributes: json_user_attributes,
      force_logout_login: json_force_logout_login,
      signature: signature
    };

    var query_string = querystring.stringify(query_params);
    return host + embed_path + '?' + query_string;

  }
  forceUnicodeEncoding(val: string) {
    return decodeURIComponent(encodeURIComponent(val));
  }

}

