import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CookieService } from 'ngx-cookie-service';
import { LiquidCacheStorageTypes, NgxLiquidCacheModule } from 'ngx-liquid-cache';
import { ToastrModule } from 'ngx-toastr';
import { environment } from './../environments/environment.prod';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import CoreModule from './core/core.module';
import AuthenticationModule from './features/authentication/authentication.module';
import DashboardModule from './features/dashboard/dashboard.module';
import SharedModule from './shared/shared.module';
import { ReportsComponent } from './features/reports/reports.component';


@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        AppRoutingModule,
        CoreModule,
        SharedModule,
        DashboardModule,
        AuthenticationModule,
        ToastrModule.forRoot(),
        NgxLiquidCacheModule.forRoot({
            storageType: LiquidCacheStorageTypes.inMemory
        }),
        StoreModule.forRoot({}),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production
        })
    ],
    declarations: [
        AppComponent,
        ReportsComponent,
        
    ],
    exports: [
        NgxDatatableModule
    ],
    providers: [CookieService],
    bootstrap: [AppComponent]
})
export class AppModule {}
