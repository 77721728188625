import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { NextConfig } from 'src/app/shared/configs/next.config';
import { NavigationItem } from 'src/app/shared/models';

@Component({
    selector: 'app-nav-collapse',
    templateUrl: './nav-collapse.component.html',
    styleUrls: ['./nav-collapse.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({transform: 'translateY(-100%)', display: 'block'}),
                animate('250ms ease-in', style({transform: 'translateY(0%)'}))
            ]),
            transition(':leave', [
                animate('250ms ease-in', style({transform: 'translateY(-100%)'}))
            ])
        ])
    ]
})
export class NavCollapseComponent implements OnInit {

    @Input() item: NavigationItem;

    public visible: boolean;
    public nextConfig: any;
    public themeLayout: string;

    ngOnInit(): void {
        this.visible = false;
        this.nextConfig = NextConfig.config;
        this.themeLayout = this.nextConfig.layout;
    }

    navCollapse(e): void {
        this.visible = !this.visible;

        let parent = e.target;
        if (this.themeLayout === 'vertical') {
            parent = parent.parentElement;
        }

        const sections = document.querySelectorAll('.pcoded-hasmenu');
        for (let i = 0; i < sections.length; i++) {
            if (sections[i] !== parent) {
                sections[i].classList.remove('pcoded-trigger');
            }
        }

        let firstParent = parent.parentElement;
        let preParent = parent.parentElement.parentElement;
        if (firstParent.classList.contains('pcoded-hasmenu')) {
            do {
                firstParent.classList.add('pcoded-trigger');
                // firstParent.parentElement.classList.toggle('pcoded-trigger');
                firstParent = firstParent.parentElement.parentElement.parentElement;
            } while (firstParent.classList.contains('pcoded-hasmenu'));
        } else if (preParent.classList.contains('pcoded-submenu')) {
            do {
                preParent.parentElement.classList.add('pcoded-trigger');
                preParent = preParent.parentElement.parentElement.parentElement;
            } while (preParent.classList.contains('pcoded-submenu'));
        }
        parent.classList.toggle('pcoded-trigger');
    }
}
