import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-animation-modal',
    templateUrl: './animation-modal.component.html',
    styleUrls: ['./animation-modal.component.scss']
})
export class AnimationModalComponent {

    @Input() modalClass: string;
    @Input() contentClass: string;
    @Input() modalID: string;
    @Input() backDrop = false;

    public close(event) {
        document.querySelector('#' + event).classList.remove('md-show');
    }
}
