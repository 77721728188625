import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NextConfig } from '../../configs/next.config';
import { GlobalsService } from './../../../core/services/globals.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

    @Output() onNavCollapse = new EventEmitter();
    @Output() onNavHeaderMobCollapse = new EventEmitter();

    public nextConfig: any;
    public menuClass: boolean;
    public collapseStyle: string;
    public windowWidth: number;
    public username: string;
    public version: string;
    public isSuperUser: boolean;

    constructor(private globalService: GlobalsService) {}

    ngOnInit(): void {
        this.username = this.globalService.username;
        this.isSuperUser = this.globalService.isSuperUser;
        this.nextConfig = NextConfig.config;
        this.menuClass = false;
        this.collapseStyle = 'none';
        this.windowWidth = window.innerWidth;
        this.version = environment.version;
    }

    toggleMobOption() {
        this.menuClass = !this.menuClass;
        this.collapseStyle = (this.menuClass) ? 'block' : 'none';
    }

    navCollapse() {
        if (this.windowWidth >= 992) {
            this.onNavCollapse.emit();
        } else {
            this.onNavHeaderMobCollapse.emit();
        }
    }

}
