import { UserData } from './../../../../shared/models/user-data';
export const feauterKey = 'userModule';

export const featureKey = 'userModule';

export interface State {
    user: UserData;
    loading: boolean;
}

export const initialState: State = {
    user: null,
    loading: false
}
