// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    version: '5.1.0',
    apiUrl: 'https://dev-api-retailinsight.flywheeldigital.com/ums2',
    portalUrl: 'https://dev-retailinsight.flywheeldigital.com',
    contentfulAPIURL : 'https://dev-api-retailinsight.flywheeldigital.com/contentful2/',
    //contentfulAPIURL : 'http://localhost:3002/local/',
    embed_domain: 'https://dev-admin-retailinsight.flywheeldigital.com',
    salesforce: {
      url: 'https://ascential--devhub.my.salesforce.com',
      validAccountIdBeginning: '0017E',
      validSubscriptionIdBeginning: 'a3F7E',
      validIntegrationContactIdBeginning: 'a3H7E'
    },
    aws: {
        storeToursKey: 'le5HAilkud3AEHXnw891j2anLgi5NWlMa9xCdZ0Z',
        umsKey: 'mVYMNwTnMs7v1BBbXngKT9rncBb3t5be9gbTZWsS',
        adminGroup: 'eri_admin_role',
        superUserGroup: 'eri_super_user_role',
        ums20key:'BwLEi4DH0h2lHkRT3n3xAaoZwM9Se88j1rYjRGPG',
        // xApiKey: '6gCDua8qRf40rtM6MmMZX8ETHWK78NRW3zjkekDa',
        // storeToursKey: '4S9A7xfSEN5cbWpqNGUaq5goEbXaZ7rl2pqTiZoW',
    },
    secretKey: '491ff910-5c2c-454e-adfc-2b28c6f739b4',
    clientId: 'd5fa105f-3e83-41da-8891-ab0bf18878ef',
    clientName: 'ERI-UMS',
    apiKey: '87e56c26-f7b5-4fde-80c3-fa554994dbc8',
    amplify: {
      Auth: {
        region: 'us-west-2',
        userPoolId: 'us-west-2_HrSOG6KC3',
        userPoolWebClientId: '2f39t1fkh4m9del1gfg30ec432',
        identityPoolId: 'us-west-2:93bf3ea6-bf51-44b0-b459-580d38a7e780',
        mandatorySignIn: false,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        cookieStorage: {
          // REQUIRED - Cookie domain (only required if cookieStorage is provided)
          domain: '.flywheeldigital.com',
          // OPTIONAL - Cookie path
          path: '/',
          // OPTIONAL - Cookie expiration in days
          expires: 365,
          // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
          sameSite: 'strict',
          // OPTIONAL - Cookie secure flag
          // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
          secure: true
        },
      },
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
