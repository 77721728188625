import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor(private router: Router,
        private route: ActivatedRoute) {};

    navigateToLoginPage(): void {
        window.location.assign(`${environment.portalUrl}/login`);
    }

    navigateByNotAutorizedUrl(): void {
        this.router.navigateByUrl(`${environment.portalUrl}/login`);
    }

    navigateByUrl(url: string): Promise<boolean> {
        return this.router.navigateByUrl(url);
    }

    navigateToRelativePath(url: string): Promise<boolean> {
        url = `/ums/${url}`;
        return this.router.navigate([url], { relativeTo: this.route });
    }

    navigateTo(commands: any[], extras?: NavigationExtras): void {
        this.router.navigate(commands, extras);
    }
}
