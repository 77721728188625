import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-footer-content',
    templateUrl: './modal-footer-content.component.html',
    styleUrls: ['./modal-footer-content.component.scss']
})
export class ModalFooterContentComponent implements OnChanges {

    @Input() showConfirmBtn = true;
    @Input() confirmBtnText = 'Confirm';
    @Input() closeBtnText = 'Close';
    @Input() disabledConfirmBtn = false;
    @Input() disabledConfirmBtnTitle = 'Disabled';

    @Output() closed = new EventEmitter<boolean>();
    @Output() confirmed = new EventEmitter<boolean>();

    title = this.disabledConfirmBtnTitle;

    constructor(private activeModal: NgbActiveModal) {}

    ngOnChanges(changes: SimpleChanges): void {
        const disabledStatus = changes.disabledConfirmBtn.currentValue;
        this.title = disabledStatus
            ? this.disabledConfirmBtnTitle
            : this.confirmBtnText;
    }

    dismiss(): void {
        this.activeModal.dismiss(false);
        this.closed.emit(false);
    }

    confirm(): void {
        this.confirmed.emit(true);
    }
}
