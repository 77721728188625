import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import SharedModule from 'src/app/shared/shared.module';
import { DashboardComponent } from './components';
import { ConfigurationComponent } from './components/layout/configuration/configuration.component';
import { NavCollapseComponent } from './components/layout/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavContentComponent } from './components/layout/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './components/layout/navigation/nav-content/nav-group/nav-group.component';
import { NavItemComponent } from './components/layout/navigation/nav-content/nav-item/nav-item.component';
import { NavigationComponent } from './components/layout/navigation/navigation.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule
    ],
    declarations: [
        DashboardComponent,
        ConfigurationComponent,
        NavigationComponent,
        NavContentComponent,
        NavItemComponent,
        NavGroupComponent,
        NavCollapseComponent
    ],
    exports: [DashboardComponent]
})
export default class DashboardModule {}