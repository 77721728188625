import { TemplateRef } from '@angular/core';

export class DataTableColumn {
    name: string;
    property: string;
    type?: string;
    sortable ?= false;
    filterable ?= false;
    disabled ?= false;
    width?: string | number;
    template?: TemplateRef<any>;
    multipleLineTextDisplay ?= false;
}
