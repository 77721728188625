import { NgForOf } from '@angular/common';
import { Directive, Host, Input } from '@angular/core';

/**
 * Improve Angular *ngFor performance through this directive.
 * https://netbasal.com/angular-2-improve-performance-with-trackby-cc147b5104e5
 *
 * How we should use it:
 *  If wants to **Track By Index**:
 *      <li *ngFor="let item of Items; trackByProperty">
 *
 *  If wants to **Track By Specific Property**:
 *      <li *ngFor="let item of Items; trackByProperty: 'id'">
 *
 * **Note**
 * Default value is **trackByIndex**.
 * If expect the items will be added / removed in the source collection over time
 * then don't use the default value.
 */
@Directive({
    selector: '[ngForTrackByProperty]'
})
export class NgForTrackByPropertyDirective<T> {

    @Input() ngForTrackByProperty?: keyof T;

    constructor(@Host() private ngFor: NgForOf<T>) {
        this.ngFor.ngForTrackBy = this.trackByPropertyFn;
    }

    private trackByPropertyFn = (index: number, item: T) => {
        if (this.ngForTrackByProperty) {
            return item[this.ngForTrackByProperty];
        }
        return index;
    }
}
