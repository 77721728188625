import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ListItem } from './../../models/list-item';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => SelectComponent),
          multi: true
        }
    ]
})
export class SelectComponent implements ControlValueAccessor {

    @Input() id: string;
    @Input() disabled = false;
    @Input() items: ListItem[];
    @Input() selectedValue: string;
    @Input() multiple = true;
    @Input() placeholder = 'Select item';
    @Input() bindLabel = 'label';
    @Input() bindValue = 'value';

    form: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder) {}

    get selectControl() {
        return this.form.get('selectCtrl');
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            selectCtrl: [
                {
                    value: this.selectedValue,
                    disabled: this.disabled
                }
            ]
        });
    }

    onTouched: () => void = () => {};

    writeValue(val: any): void {
        this.selectControl.setValue(val);
    }

    registerOnChange(fn: any): void {
        this.form.valueChanges.subscribe(fn);
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.form.disable() : this.form.enable();
    }
}
