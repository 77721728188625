import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

interface AttributeData {
    Name: string;
    Value: string;
}

@Injectable({
    providedIn: 'root'
})
export class GlobalsService {

    private _username: string;
    private _userid: string;
    isSuperUser: boolean;

    constructor(private cookieService: CookieService) {}

    get username(): string {
        const userDataAttributes: AttributeData[] = this.getCookies(environment.amplify.Auth.userPoolWebClientId, 'userData')?.UserAttributes;

        if (!userDataAttributes) {
            return this._username || '';
        }
        
        const name = userDataAttributes

            .filter(attr => attr.Name === 'given_name' || attr.Name === 'family_name')
            .map(attr => attr.Value)
            .reduce((prev, curr) => prev + ' ' + curr, '');
        return name || '';
    }

    set username(value: string) {
        this._username = value;
    }
    get userid(): string {
        const userDataAttributes: AttributeData[] = this.getCookies(environment.amplify.Auth.userPoolWebClientId, 'userData')?.UserAttributes;

        if (!userDataAttributes) {
            return this._userid || '';
        }
        
        const name = userDataAttributes
            .find(attr => attr.Name === 'sub').Value
        return name || '';
    }
    set userid(value: string) {
        this._userid = value;
    }

    private getCookies(userPoolWebClientId: string, key: string) {
        const allCookies = this.cookieService.getAll();

        for (let cookie in allCookies) {
            if(cookie.includes(key) && cookie.includes(userPoolWebClientId)) {
                return JSON.parse(allCookies[cookie]);
            }
        }
    }
}