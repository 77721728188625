type ModalSize = 'sm' | 'lg' | 'xl';

export class ModalOptions {
    size?: ModalSize;
    centered ?= true;
    scrollable ?= false;
    backdrop?: boolean | 'static' = 'static';
    animation ?= true;
    keyboard ?= true;
    beforeDismiss?: () => boolean | Promise<boolean>;

    /**
     * A custom class to append to the modal window.
     */
    windowClass?: string;

    /**
     * A selector specifying the element all new modal windows should be appended to.
     * Since v5.3.0 it is also possible to pass the reference to an `HTMLElement`.
     *
     * If not specified, will be `body`.
     */
    container?: string | HTMLElement;

     /**
     * A custom class to append to the modal backdrop.
     *
     * @since 1.1.0
     */
    backdropClass?: string;
}