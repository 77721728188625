import { Component, EventEmitter, forwardRef, Input, OnInit, Output, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

interface TagInputData {
    display: string;
    value: string;
}

@Component({
    selector: 'app-tag-input',
    templateUrl: './tag-input.component.html',
    styleUrls: ['./tag-input.component.scss'],
    providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => TagInputComponent),
          multi: true
        }
    ]
})
export class TagInputComponent implements OnChanges, OnInit, ControlValueAccessor {

    @Input() placeholder = '+ Add Item';
    @Input() modelAsStrings = false;
    @Input() disable = false;

    @Output() addItemEvt = new EventEmitter<string | TagInputData>();
    @Output() removeItemEvt = new EventEmitter<string | TagInputData>();

    form: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder,
        private changeDetectorRef: ChangeDetectorRef) {}

    get tagInputControl() {
        return this.form.get('inputCtrl');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disable && this.form) {
            this.changeDetectorRef.detectChanges();
        }
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            inputCtrl: ['']
        });
    }

    onTouched: () => void = () => {};

    writeValue(val: any): void {
        this.tagInputControl.setValue(val);
    }

    registerOnChange(fn: any): void {
        this.form.valueChanges.subscribe(fn);
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.form.disable() : this.form.enable();
    }
}
