import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/services/auth-guard';
import { AuthComponent } from './features/authentication/components/auth/auth.component';
import { DashboardComponent } from './features/dashboard/components';
import { ReportsComponent } from './features/reports/reports.component';

const routes: Routes = [
    {
        path: 'ums',
        component: DashboardComponent,
        children: [
            {
                path: 'users',
                loadChildren: () => import('./features/users/users.module').then(m => m.default),
                canActivate: [AuthGuard],
                data: { allowedRoles: ['admin', 'storeToursUser'] }
            },
            {
                path: 'accounts',
                loadChildren: () => import('./features/accounts/accounts.module').then(m => m.default),
                canActivate: [AuthGuard],
                data: { allowedRoles: ['admin', 'storeToursUser'] }
            },
            {
                path: 'home',
                data: { allowedRoles: ['admin', 'storeToursUser'] },
                component: AuthComponent,
            },
            { path: 'reports',  component: ReportsComponent,  canActivate: [AuthGuard] }, // no content type here, but will be pushed through with the dashboard
            
        ]
    },
    {
        path: '**',
        redirectTo: 'ums/home',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
