import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services';
import { userActions, userState } from 'src/app/features/users/shared/store';
import { UserService } from 'src/app/shared/services';
import { PartialAccountData, UserData } from './../../../models';
import { PartialUserData } from './../../../models/user-data';

@Component({
    selector: 'app-add-edit-user',
    templateUrl: './add-edit-user.component.html',
    styleUrls: ['./add-edit-user.component.scss']
})
export class AddEditUserComponent implements OnInit, OnDestroy {

    title: string;
    user: UserData;
    account: PartialAccountData;
    isProcessingRequest = false;
    isAddUser = false;

    private readonly onDestroy = new Subject<void>();

    constructor(private userService: UserService,
        private toastrService: ToastrService,
        private activeModal: NgbActiveModal,
        private navigationService: NavigationService,
        private store: Store<userState.State>) {}

    ngOnInit(): void {
        this.isAddUser = !this.user;
        this.title = !this.isAddUser
            ? this.user?.userName ?? this.user.email
            : `${this.account.accountName} - New User Registration`;
    }

    saveData(data: PartialUserData, continueToUserDetail = false) {
        
        const saveMethod = this.isAddUser
            ? this.createUser.bind(this, data, continueToUserDetail)
            : this.updateUser.bind(this);

        this.isProcessingRequest = true;
        return saveMethod(data)
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.isProcessingRequest = false)
            )
            .subscribe(response => {
                this.toastrService.success(response['message']);
                this.closeDialog();
            });
    }

    private createUser(data: PartialUserData, continueToUserDetail = false) {
        return this.userService.createUser(data)
            .pipe(
                tap({
                    next: _ => {
                        if (continueToUserDetail) {
                            this.navigateToUserDetail(data.email);
                        }
                    }
                })
            )
    }

    private updateUser(data: PartialUserData) {
        return this.userService.updateUser(data)
            .pipe(
                tap({
                    next: _ => this.dispatchUpdateUser(Object.assign({}, this.user, data))
                })
            )
    }

    private dispatchUpdateUser(user: UserData): void {
        this.store.dispatch(userActions.updateUser({user: user}));
    }

    private closeDialog(): void {
        this.activeModal.close();
    }

    private navigateToUserDetail(userName: string): void {
        this.navigationService.navigateToRelativePath(`users/${userName}`);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
    }
}
