import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as userState from './user.state';

export const selectFeature = createFeatureSelector<userState.State>(userState.feauterKey);

export const selectUser = createSelector(selectFeature, (state: userState.State) =>
    !!state && state.user);

export const getLoading = createSelector(selectFeature, (state: userState.State) =>
    !!state && state.loading);
