import { animate, AnimationBuilder, AnimationPlayer, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    providers: [NgbDropdownConfig],
    animations: [
        trigger('collapsedCard', [
            state('collapsed, void',
                style({
                    overflow: 'hidden',
                    height: '0px',
                })
            ),
            state('expanded',
                style({
                    overflow: 'hidden',
                    height: AUTO_STYLE,
                })
            ),
            transition('collapsed <=> expanded', [
                animate('400ms ease-in-out')
            ])
        ]),
        trigger('cardRemove', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0,
                display: 'none'
            })),
            transition('open <=> closed', animate('400ms')),
        ])
    ]
})
export class CardComponent implements OnInit {

    @Input() cardTitle: string;
    @Input() cardClass: string;
    @Input() blockClass: string;
    @Input() headerClass: string;
    @Input() options: boolean;
    @Input() hideHeader: boolean;
    @Input() hideDropdownMenu = true;
    @Input() customHeader: boolean;
    @Input() cardCaption: string;
    @Input() captionClass: string;
    @Input() isCardFooter: boolean;
    @Input() footerClass: string;
    @Input() loadCard:boolean;

    fullIcon: string;
    collapsedCard: string;
    collapsedIcon: string;
    cardRemove: string;

    @ViewChild('toAnimate') elementRef: ElementRef;
    private player: AnimationPlayer;

    constructor(private builder: AnimationBuilder,
        config: NgbDropdownConfig) {
        config.placement = 'bottom-right';
        this.customHeader = false;
        this.options = true;
        this.hideHeader = false;
        this.isCardFooter = true;
        this.cardTitle = '';
        this.fullIcon = 'icon-maximize';
        this.collapsedCard = 'expanded';
        this.collapsedIcon = 'icon-minus';
        this.loadCard = false;
        this.cardClass="card-load"
        this.cardRemove = 'open';
    }

    ngOnInit(): void {
        if (this.hideHeader) {
            this.options = false;
        }

        if (!this.options || this.hideHeader || this.customHeader) {
            this.collapsedCard = 'false';
        }
    }

    fullCardToggle() {
        const animation = this.cardClass === 'full-card' ? 'zoomOut' : 'zoomIn';
        this.fullIcon = this.cardClass === 'full-card' ? 'icon-maximize' : 'icon-minimize';
        this.cardClass = animation === 'zoomOut' ? '' : 'full-card';
        this.player = this.createPlayer(this.elementRef.nativeElement);
        this.player.play();
    }

    private createPlayer(element: HTMLElement): AnimationPlayer {
        if(this.player) {
            this.player.destroy();
        }

        return this.builder.build([
            animate(
                200,
                style({'direaction': 'alternate'})
            )
        ])
        .create(element);
    }

    collapsedCardToggle() {
        this.collapsedCard = this.collapsedCard === 'collapsed' ? 'expanded' : 'collapsed';
        this.collapsedIcon = this.collapsedCard === 'collapsed' ? 'icon-plus' : 'icon-minus';
    }

    cardRefresh() {
        this.loadCard = true;
        this.cardClass = 'card-load';

        setTimeout( () => {
            this.loadCard = false;
            this.cardClass = 'expanded';
        }, 3000);
    }

    cardRemoveAction() {
        this.cardRemove = this.cardRemove === 'closed' ? 'open' : 'closed';
    }
}
