import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ApexChartService {

    @Output() changeTimeRange: EventEmitter<boolean> = new EventEmitter();
    @Output() changeSeriesData: EventEmitter<boolean> = new EventEmitter();

    eventChangeTimeRange() {
        this.changeTimeRange.emit();
    }

    eventChangeSeriesData() {
        this.changeSeriesData.emit();
    }
}
