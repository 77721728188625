import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from './modal-options';

@Injectable({
    providedIn: 'root'
})
export class UiModalService {

    constructor(private modalService: NgbModal) {}

    openModal(content: any, options?: ModalOptions) {
        const opts = this.extendModalDefaultOptions(options);
        return this.modalService.open(content, opts);
    }

    private extendModalDefaultOptions(options: ModalOptions): ModalOptions {
        const defaultOptions = new ModalOptions();
        return {...defaultOptions, ...options};
    }
}
