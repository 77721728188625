import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TagInputModule } from 'ngx-chips';
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import CoreModule from '../core/core.module';
import { CardComponent } from './components';
import { AlertComponent } from './components/alert/alert.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ApexChartComponent } from './components/chart/apex-chart/apex-chart.component';
import { CheckboxColumnComponent, LinkColumnComponent } from './components/data-table/custom-columns';
import { DataTableComponent } from './components/data-table/data-table.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { AnimationModalComponent, ModalFooterContentComponent, ModalHeaderContentComponent, UiModalComponent } from './components/modal';
import { NavBarComponent, NavLeftComponent, NavRightComponent, NavSearchComponent } from './components/nav-bar';
import { SelectComponent } from './components/select/select.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TabstripComponent } from './components/tabstrip/tabstrip.component';
import { TagInputComponent } from './components/tag-input/tag-input.component';
import { AddEditUserComponent, UserFormComponent } from './components/user';
import { NgForTrackByPropertyDirective, ConnectFormDirective } from './directives';
import { DataFilterPipe } from './pipes/data-filter.pipe';
import { LookerEmbedsComponent } from './components/looker-embeds/looker-embeds.component';
import { PowerBiComponent } from './components/power-bi/power-bi.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        CoreModule,
        NgbModule,
        NgbDropdownModule,
        NgSelectModule,
        NgxDatatableModule,
        AutocompleteLibModule,
        TagInputModule
    ],
    declarations: [
        NavBarComponent,
        NavLeftComponent,
        NavSearchComponent,
        NavRightComponent,
        AlertComponent,
        BreadcrumbComponent,
        CardComponent,
        ApexChartComponent,
        DataFilterPipe,
        UiModalComponent,
        AnimationModalComponent,
        SpinnerComponent,
        AutocompleteComponent,
        ModalHeaderContentComponent,
        ModalFooterContentComponent,
        DropdownComponent,
        SelectComponent,
        TagInputComponent,
        TabstripComponent,
        DataTableComponent,
        LinkColumnComponent,
        CheckboxColumnComponent,
        AddEditUserComponent,
        UserFormComponent,

        NgForTrackByPropertyDirective,
        ConnectFormDirective,
        LookerEmbedsComponent,
        PowerBiComponent
    ],
    exports: [
        NavBarComponent,
        NavLeftComponent,
        NavSearchComponent,
        NavRightComponent,
        AlertComponent,
        BreadcrumbComponent,
        CardComponent,
        ApexChartComponent,
        DataFilterPipe,
        UiModalComponent,
        AnimationModalComponent,
        SpinnerComponent,
        AutocompleteComponent,
        ModalHeaderContentComponent,
        ModalFooterContentComponent,
        DropdownComponent,
        SelectComponent,
        TagInputComponent,
        TabstripComponent,
        DataTableComponent,
        LinkColumnComponent,
        AddEditUserComponent,
        UserFormComponent,
        LookerEmbedsComponent,
        PowerBiComponent,
        NgForTrackByPropertyDirective,
        ConnectFormDirective
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ]
})
export default class SharedModule {}