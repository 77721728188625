import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-link-column',
    templateUrl: './link-column.component.html',
    styleUrls: ['./link-column.component.scss']
})
export class LinkColumnComponent {

    @Input() property: string;
    @Input() dataItem: any;

    @Output() keyFieldClick = new EventEmitter<any>();

    onItemClick(dataItem: any): void {
        this.keyFieldClick.emit(dataItem);
    }
}
