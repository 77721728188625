export class NextConfig {
    public static config = {
        layout: 'vertical', // vertical, horizontal
        subLayout: '', // horizontal-2
        collapseMenu: false,
        layoutType: 'menu-dark', // menu-dark, menu-light, dark
        headerBackColor: 'header-blue', // header-default, header-blue, header-red, header-purple, header-info, header-dark
        navBrandColor: 'brand-default', // brand-default, brand-blue, brand-red, brand-purple, brand-info, brand-dark
        rtlLayout: false,
        navFixedLayout: true,
        headerFixedLayout: true,
        boxLayout: false,
    };
  }
