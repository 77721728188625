import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import SharedModule from 'src/app/shared/shared.module';
import { AuthComponent } from './components/auth/auth.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthRoutingModule } from './authentication-routing.module';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AuthRoutingModule
    ],
    declarations: [
        AuthComponent
    ],
    exports: [RouterModule]
})
export default class AuthenticationModule {}
