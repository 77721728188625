// import { AccountMagicLink } from './account-magic-link';
// import { SubscriptionData } from './subscription-data';

export interface AccountLogData {
    accountId: string;
    recordType: string;
    timestamp: string;
    log: AccountLog[];
    updatedBy: string;
}

export interface AccountLog {
    accountName: ChangeLog;
    accountOwner: ChangeLog;
    cohort: ChangeLog;
    sfId: ChangeLog;
    active: ChangeLog;
    // domains: string[];
    notes: ChangeLog;
    ceManager: ChangeLog;
    accountDirector: ChangeLog;
    contentAnalyst: ChangeLog;
    analystHours: ChangeLog;
    briefings: ChangeLog;
    videos: ChangeLog;
    newsletters: ChangeLog;
    clientUrl: ChangeLog;
    clientHeadquarters: ChangeLog;
    budgetHolder: ChangeLog;
    influencer: ChangeLog;
    techPerson: ChangeLog;
    revenue: ChangeLog;
}

export interface ChangeLog {
    old: string;
    new: string;
}