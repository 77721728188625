import { Injectable } from '@angular/core';
import { Tokens } from '../models/tokens';

@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {

    private readonly JWT_TOKEN = 'access_token';
    private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';

    storeTokens(tokens: Tokens) {
        localStorage.setItem(this.JWT_TOKEN, tokens.jwt);
        localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
    }

    getJwtToken() {
        return localStorage.getItem(this.JWT_TOKEN);
    }

    getRefreshToken() {
        return localStorage.getItem(this.REFRESH_TOKEN);
    }

    storeJwtToken(jwt: string) {
        localStorage.setItem(this.JWT_TOKEN, jwt);
    }

    removeTokens() {
        localStorage.removeItem(this.JWT_TOKEN);
        localStorage.removeItem(this.REFRESH_TOKEN);
    }
}
