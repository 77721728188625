import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DropdownListItem } from './dropdown-list-item';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnChanges {

    @Input() dropdownBtnClass = 'btn dropdown-btn-class';
    @Input() dropdownClass: string;
    @Input() autoClose = true;
    @Input() open = false;
    @Input() placement = 'bottom-left bottom-right top-left top-right';
    @Input() disabled = false;
    @Input() placeholder = 'Select me';
    @Input() listItems: DropdownListItem[];

    ngOnChanges(changes: SimpleChanges): void {
        const items = changes.listItems.currentValue;
        if (!items) {
            return;
        }

        this.listItems = items.map(item => this.extendListItemDefaultValues(item));
    }

    private extendListItemDefaultValues(item: DropdownListItem): DropdownListItem {
        return {...new DropdownListItem(), ...item};
    }
}
