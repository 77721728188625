import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-ui-modal',
    templateUrl: './ui-modal.component.html',
    styleUrls: ['./ui-modal.component.scss']
})
export class UiModalComponent {
    @Input() dialogClass: string;
}
