import { createReducer, on } from "@ngrx/store";
import * as actions from './user.actions';
import * as userState from './user.state';

export const featureKey = 'userModule';

const _reducer = createReducer(
    userState.initialState,
    on(actions.updateUser, (state, { user }) => {
        return {
            ...state,
            user: user,
            loading: false
        }
    }),
    on(actions.toggleUserEnableStatus, (state, { enabled }) => {
        return {
            ...state,
            user: { ...state.user, enabled: enabled },
            loading: false
        }
    })
)

export function reducer(state: userState.State, action: any) {
    return _reducer(state, action);
}
