import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const baseUrl = environment.apiUrl;

@Injectable({
    providedIn: 'root'
})
export class DataApiService {

    constructor(private httpClient: HttpClient,
        private toastrService: ToastrService) {}

    getData<T>(endResource: string): Observable<T> {
        const url = `${baseUrl}/${endResource}`;
        return this.pipeHttpRequest(this.httpClient.get<T>(url));
    }

    postData<T>(endResource: string, reqBody: any): Observable<T> {
        const url = `${baseUrl}/${endResource}`;
        return this.pipeHttpRequest(this.httpClient.post<T>(url, reqBody));
    }

    updateData<T>(endResource: string, reqBody: any, key: string): Observable<T> {
        const url = `${baseUrl}/${endResource}/${key}`;
        return this.pipeHttpRequest(this.httpClient.put<T>(url, reqBody));
    }

    patchData<T>(endResource: string, reqBody: any): Observable<T> {
        const url = `${baseUrl}/${endResource}`;
        return this.pipeHttpRequest(this.httpClient.patch<T>(url, reqBody));
    }

    deleteData<T>(endResource: string, options?: any): Observable<T | HttpEvent<T>> {
        const url = `${baseUrl}/${endResource}`;
        return this.pipeHttpRequest(this.httpClient.delete<T>(url, options));
    }

    private pipeHttpRequest<T>(ObservableReq: Observable<T>): Observable<T> {
        return ObservableReq.pipe(
            catchError(response => {
                this.toastrService.error(response.error ? response.error.message : response.message);
                return throwError(response)
            })
        );
    }
}
