import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as pbi from 'powerbi-client';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
// import { PowerBIServiceTsService } from 'src/app/core/_services/power-bi.service.ts.service';
import { firstValueFrom, withLatestFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { query } from '@angular/animations';
const flywheeltheme = require('src/app/core/models/flywheeltheme.json');

const powerbi: pbi.service.Service = window["powerbi"];

@Component({
  selector: 'app-power-bi',
  templateUrl: './power-bi.component.html',
  styleUrls: ['./power-bi.component.scss']
})
export class PowerBiComponent implements OnChanges {
  report: any;
  reportName: string;
  @Input() showReportName: boolean = true;

  dataLoaded: boolean = false
  reportFinishedLoading: boolean = false;
  hasAccess: boolean;
  lookerPermissions: any = [];
  userId: string;
  accountId: string;  

  isClientWorkspace: boolean;
  clientReportLoaded: boolean;

  allowSaving: boolean = false;
  allowExportData: boolean = false;
  @Input() showSaveButtons: boolean = true;
  @Input() passedInWorkspaceId: string;
  @Input() passedInReportId: string;
  @Input() filterSettings: any = [];


  // Set the refresh interval time to 30 seconds
INTERVAL_TIME: number = 30000;
MINUTES_BEFORE_EXPIRATION: number = 10;
tokenExpiration: any;

private contentfulAPIURL = environment.contentfulAPIURL;


constructor(
  private route: ActivatedRoute,
  private router: Router,
  private http: HttpClient) {

    

}

ngOnChanges(changes: SimpleChanges) {
  console.log(`changes =======`)
  console.log(changes)
  if (changes['passedInReportId']) {
    console.log('passedInReportId has changed:', changes['passedInReportId'].currentValue);
    // You can also access previous values:
    console.log('Previous value:', changes['passedInReportId'].previousValue);

    // this.passedInReportId = changes['passedInReportId'].currentValue;
  }
  if (changes['passedInWorkspaceId']) {
    console.log('passedInWorkspaceId has changed:', changes['passedInWorkspaceId'].currentValue);
    // You can also access previous values:
    console.log('Previous value:', changes['passedInWorkspaceId'].previousValue);

    // this.passedInWorkspaceId = changes['passedInWorkspaceId'].currentValue;
  }
}

async ngOnInit(): Promise<void> {  







    this.route.url.pipe(
      withLatestFrom(this.route.paramMap, this.route.queryParamMap)
    ).subscribe(([url, paramMap, queryParamMap]) => {

      // console.log(`this.route.url.pipe **************`)

      let reportId = paramMap.get('id');
      let workspaceId = paramMap.get('workspaceId');

      if (reportId && workspaceId) {
        this.passedInReportId = reportId
        this.passedInWorkspaceId = workspaceId
      }


      queryParamMap.keys.forEach(async key => {
        const value = queryParamMap.get(key);  
        
        await this.addFilter(key, value)
      });


      // console.log('*************************************')
      // console.log(`reportId: `, reportId)
      // console.log(`workspaceId: `, workspaceId)
      // console.log(`this.filterSettings: `, this.filterSettings)

      this.report = null
      this.embedPowerBIReport();
    


    });

    // this.route.queryParams.subscribe(async queryParams => {


    //   if (Object.keys(queryParams).length > 0) {

    //     for (let key in queryParams) {
    //       if (queryParams.hasOwnProperty(key)) {
    //         console.log(`${key}: ${queryParams[key]}`);
    //         await this.addFilter(key, queryParams[key])
    //       }
    //     }  

    //   }



    //   this.report = null
    //   this.embedPowerBIReport();

    // });








  //   this.router.events.pipe(filter(event=>event instanceof NavigationEnd))
  //  .subscribe(event=>{
  //      let reportId = this.route.snapshot.params.id;
  //      let workspaceId = this.route.snapshot.params.workspaceId

  //      let queryParams = this.route.snapshot.queryParams;

  //      console.log('*************************************')
  //      console.log(`reportId: `, reportId)
  //      console.log(`workspaceId: `, workspaceId)
  //      console.log(`queryParams: `, queryParams)
  //  });


  
    // this.route.params.subscribe(params => {

    //   this.uiService.togglePageLoadIcon(true)
      
    //   // console.log('route.params.subscribe')
    //   // console.log(params)
  
    //   if (params && params.id && params.workspaceId) {
    //     this.passedInReportId = params.id
    //     this.passedInWorkspaceId = params.workspaceId
    //   }
  

    //   this.route.queryParams.subscribe(async queryParams => {

    //     if (Object.keys(queryParams).length > 0) {
  
    //       for (let key in queryParams) {
    //         if (queryParams.hasOwnProperty(key)) {
    //           console.log(`${key}: ${queryParams[key]}`);
    //           await this.addFilter(key, queryParams[key])
    //         }
    //       }  
  
    //     }
  
    //   });
  
    //   this.embedPowerBIReport();
    
    //   setInterval(() => { this.checkTokenAndUpdate() }, this.INTERVAL_TIME);
  
    //   document.addEventListener("visibilitychange", () => {​​​​
    //     console.log('visibilitychange')
    //     // Check the access token when the tab is visible
    //     if (!document.hidden) {​
    //         console.log('!document.hidden')
    //         this.checkTokenAndUpdate()
    //     }​​​​
    //   }​​​​);


  
    // });




  


  
      
}


async addFilter(key: string, value: string) {
  let newFilter;
  switch (key) {
    case 'retailer':
      newFilter = {
        filter: {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
              table: "Retailer Data",
              column: " Retailer"
          },
          filterType: pbi.models.FilterType.Basic,
          operator: "Equals",
          values: [value],
        },
        slicerVisualTitle: "Retailer"
        
      };
      break;
    case 'market':
      newFilter = {
        filter: {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
              table: "Geography",
              column: "Market"
          },
          filterType: pbi.models.FilterType.Basic,
          operator: "Equals",
          values: [value],
        },
        slicerVisualTitle: "Market"
        
      };
      break;
    case 'productcategory':
      newFilter = {
        filter: {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
              table: "Retailer Data",
              column: "Product Category"
          },
          filterType: pbi.models.FilterType.Basic,
          operator: "Equals",
          values: [value],
        },
        slicerVisualTitle: "Product Category"
        
      };
      break;
  }

  if (newFilter) {
    this.filterSettings.push(newFilter);
  }
  
}

exportToPdf() {
//   this.report.export(pbi.models.ExportDataType.Summarized).then((response: any) => {
//     console.log('Export successful', response);
//     // 'response' will contain the file in base64 format which you can download or handle
// }).catch((errors:any) => {
//     console.error('Export failed', errors);
// });
}



async checkTokenAndUpdate() {
    // Get the current time
    const currentTime = Date.now();
    const expiration = Date.parse(this.tokenExpiration);

    // console.log('checkTokenAndUpdate()')
    // console.log(`expiration: ${expiration}`)

    // Time until token expiration in milliseconds
    const timeUntilExpiration = expiration - currentTime;
    const timeToUpdate = this.MINUTES_BEFORE_EXPIRATION * 60 * 1000;

    // console.log(`timeUntilExpiration: ${timeUntilExpiration}`)

    // Update the token if it is about to expired
    if (timeUntilExpiration <= timeToUpdate)
    {
        console.log("Updating report access token");
        this.updateToken();
    }
}

async updateToken() {
    
    let url = this.contentfulAPIURL + `powerbi/reports/${this.passedInReportId}/workspaces/${this.passedInWorkspaceId}`;

    this.http.get(url).subscribe(async (newAccessToken:any) => {

      console.log(`newAccessToken`)
      console.log(newAccessToken)
  
      // Update the new token expiration time
      this.tokenExpiration = newAccessToken.expiry;
  
      console.log(`new tokenExpiration: ${this.tokenExpiration}`)
      console.log(`setAccessToken: ${newAccessToken.accessToken}`)
  
      // Set the new access token
      await this.report.setAccessToken(newAccessToken.accessToken);
  
      console.log(`finished setting new access token =============`)
    });

    
}




async embedPowerBIReport() {

  this.clientReportLoaded = false;

  // this.route.params.subscribe(params => {
  //     let reportId = params.id
  //     let workspaceId = params.workspaceId

      // console.log(`this.passedInReportId:`, this.passedInReportId)
      // console.log(`this.passedInWorkspaceId`, this.passedInWorkspaceId)
      

      
      // this.isClientWorkspace = this.passedInWorkspaceId == environment.powerBIClientWorkspaceId ? true : false;
      
      this.showReportName = this.isClientWorkspace ? true : false;
  
      let url = this.contentfulAPIURL + `powerbi/reports/${this.passedInReportId}/workspaces/${this.passedInWorkspaceId}`;
       
      this.http.get(url).subscribe((response: any) => {
  
          // console.log(`response`, response)
          

          
  
        let accessToken = response.accessToken
        let embedUrl
        let reportId
  
        if (response.embedUrl && response.embedUrl.length > 0) {
  
          embedUrl = response.embedUrl[0].embedUrl// + `?filter=Retailer_x0020_Data/_x0020_Retailer eq 'Tesco'`
          console.log(`embedUrl:`, embedUrl)
          reportId = response.embedUrl[0].reportId
          this.reportName = response.embedUrl[0].reportName

          this.tokenExpiration = response.expiry;       
          
          setInterval(() => { this.checkTokenAndUpdate() }, this.INTERVAL_TIME);
  
          document.addEventListener("visibilitychange", () => {​​​​
            console.log('visibilitychange')
            // Check the access token when the tab is visible
            if (!document.hidden) {​
                console.log('!document.hidden')
                this.checkTokenAndUpdate()
            }​​​​
          }​​​​);
  
          const embedConfig: pbi.IEmbedConfiguration = {
            type: 'report',
            id: reportId,
            embedUrl: embedUrl,
            tokenType: pbi.models.TokenType.Embed,
            accessToken: accessToken,
            permissions: pbi.models.Permissions.All,
            ...(this.isClientWorkspace ? { viewMode: pbi.models.ViewMode.Edit } : { viewMode: pbi.models.ViewMode.View }),

            settings: {
              ...(!this.allowExportData && { commands: [{exportData: { displayOption: pbi.models.CommandDisplayOption.Disabled }}] }),
              // commands: [{exportData: { displayOption: pbi.models.CommandDisplayOption.Disabled }}],

              panes: {
                  filters: {
                      visible: this.isClientWorkspace ? true : false
                  },
                  pageNavigation: {
                      visible: false
                  }
              },
              bars: {
                  actionBar: {
                      visible: false
                    },
              }
            },
            theme: {
              themeJson: flywheeltheme
            }
          };
          const reportContainer = document.getElementById(`report-container${this.passedInReportId}`);
          
          // const report =  powerbi.embed(reportContainer, embedConfig);
      
          this.report = powerbi.embed(reportContainer, embedConfig) as pbi.Report;
      
          

          // report.off removes all event handlers for a specific event
          this.report.off("loaded");
      
          this.report.on("loaded", async () => {
            console.log("Loaded");
            
            // this.reportFinishedLoading = true;

            this.clientReportLoaded = this.isClientWorkspace;

            // this.report.removeOptionsMenuCommand("exportData").then((res:any) => {
            //   console.log('removedOptionsMenu')
            //   console.log(res)
            // });

            // console.log(`this.report`, this.report)
            




            this.report.getActivePage()
            .then((activePage: any) => {
                // console.log(`activePage`, activePage)

                reportContainer.style.height = `${activePage.defaultSize.height + 50}px`;


                // console.log(`reportContainer.offsetHeight: `, reportContainer.offsetHeight)
                

                // reportContainer.style.width = '700px'
                // console.log(`activePage.defaultSize.width:  ${activePage.defaultSize.width}px`)



                // this.report.getFilters().then((filters:any) => {
                //   console.log(`filters`);
                //   console.log(filters);
                // });

                
                




            });

          
            
            
        
        
        
            try {
              // await report.applyTheme({ themeJson: theme });
              // console.log("Custom theme applied, to remove custom theme, reload the report using 'Reload' API.");
      
      
      
        
              // await report.refresh();
              // console.log("Refreshed");
            }
            catch (error) {
                console.log(error);
            }
      
          });
      
          // report.off removes all event handlers for a specific event
          this.report.off("error");
      
          this.report.on("error", (event:any) => {
              console.log(event.detail);
          });
      
          this.report.off("rendered");
      
          this.report.on("rendered", async (event:any) => {
            console.log(`Rendered`);
            
            // this.uiService.togglePageLoadIcon(false)

      
           



            if (this.filterSettings) {

              this.filterSettings = Array.from(
                new Set(this.filterSettings.map((item: any) => JSON.stringify(item)))
              ).map((jsonString: any) => JSON.parse(jsonString));

              // const filter = this.filterSettings.filter

              this.report.getActivePage()
                .then((activePage: any) => {

                this.filterSettings.forEach((filter: any) => {

                  console.log(`filter:`, filter)
                  
                  
                  const visuals = activePage.getVisuals().then((visuals: any) => {
                    // console.log(`visuals`)
                    // console.log(visuals)
                    let slicer = visuals.filter((visual:any) => {
                        return visual.type === "slicer" && visual.title === filter.slicerVisualTitle;
                    })[0];
                
                    
                    
                      // Get the slicer state.
                      slicer.getSlicerState().then((state:any) => {
                        // console.log("Slicer title: \"" + slicer.title + "\"\nSlicer state:\n", state);
                      });
                      
                  

                    // Set the slicer state which contains the slicer filters.
                    slicer.setSlicerState({ filters: [filter.filter] }).then((slicerSet:any) => {
                      console.log(" slicer was set.");
                      this.filterSettings = [];
                    }).catch((error:any) => {
                      console.error("Error setting slicer state: ", error);
                    });;

                    
                    
                  });

                });

                

              });


            }


      
          });

          // this.report.on("selectionChanged", (event:any) => {
          //   console.log('Filters changed:', event);

          //   this.report.getActivePage()
          //   .then((activePage: any) => {

              
          //     activePage.getVisuals().then((visuals: any) => {
          //       console.log(`visuals:`,visuals)
                
          //       // let slicer = visuals.filter((visual:any) => {
          //       //     return visual.type === "slicer" && visual.title === filter.slicerVisualTitle;
          //       // })[0];
            
                
                
          //       //   // Get the slicer state.
          //       //   slicer.getSlicerState().then((state:any) => {
          //       //     // console.log("Slicer title: \"" + slicer.title + "\"\nSlicer state:\n", state);
          //       //   });                          

                
                
          //     });


          //   });



            
          //   // Get all active slicer filters
          //   this.report.getFilters()
          //     .then((filters:any) => {
          //       console.log('Current filters:', filters);
          //     })
          //     .catch((error:any) => {
          //       console.error('Error getting filters:', error);
          //     });
          // });

          this.report.off("buttonClicked");
      
          this.report.on("buttonClicked", async (event:any) => {
            console.log("buttonClicked");
            console.log(event)
      
                
      
          });

          this.report.off("commandTriggered");
      
          this.report.on("commandTriggered", async (event:any) => {
            console.log("commandTriggered");
            console.log(event)
      
                
      
          });
  
  
          // report.off removes all event handlers for a specific event
          this.report.off("saved");
  
          // report.on will add an event handler which prints to Log window.
          this.report.on("saved", (event: any) => {
            console.log('saved event')
            console.log('reportObjectId')
            console.log(event.detail.reportObjectId);
            console.log('reportName')
            console.log(event.detail.reportName)
            console.log(`this.userId`)
            console.log(this.userId)
            console.log(`this.accountId`)
            console.log(this.accountId)
  
  
            // if (event.detail.reportName && event.detail.reportObjectId) {
  
            //   let saveReportUrl = this.contentfulAPIURL + 'powerbi/reports';
  
            //   let body = {
            //     userId: this.userId,
            //     accountId: this.accountId,
            //     reportName: event.detail.reportName,
            //     reportId: event.detail.reportObjectId,
            //     workspaceId: environment.powerBIClientWorkspaceId
            //   }
            //   this.http.post(saveReportUrl, body).subscribe((response: any) => {
    
            //     console.log(`response`)
            //     console.log(response)
  
            //     this.powerBIService.triggerReportSavedCall();

            //     this.router.navigate(['/retail-market-monitor', body.workspaceId, body.reportId]);
  
            //   });
  
            // }
  
              
          });
  
          // this.report.switchMode('edit')
  
  
        }
  
      })

  // })


  // let reportId = this.route.snapshot.params.id ? this.route.snapshot.params.id : '44ce5f9f-e59f-413e-963f-b347e9a832fb'
  // let workspaceId = this.route.snapshot.params.workspaceId ? this.route.snapshot.params.workspaceId : 'f8b26dcb-107b-4c08-87d4-81a36c9fd70b'









}



}
