import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TabstripOptions } from './tabstrip-options';

@Component({
    selector: 'app-tabstrip',
    templateUrl: './tabstrip.component.html',
    styleUrls: ['./tabstrip.component.scss']
})
export class TabstripComponent {

    @Input() options: TabstripOptions;

    activeTab: string;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.activeTab = this.getActiveChildPath(this.route);
    }

    private getActiveChildPath(route: ActivatedRoute): string {
        return route.firstChild.snapshot.routeConfig.path || null;
    }
}
