import { createAction, props } from '@ngrx/store';
import { UserData } from './../../../../shared/models/user-data';

export enum ActionTypes {
    UpdateUser = '[User Detail Component] update user',
    ToggleUserEnableStatus = '[User Detail Component] toggle user enable status',
}

export const updateUser = createAction(ActionTypes.UpdateUser,
    props<{user: UserData}>());

export const toggleUserEnableStatus = createAction(ActionTypes.ToggleUserEnableStatus,
    props<{enabled: boolean}>());
