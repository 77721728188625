import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataApiService } from 'src/app/core/services';
import { AccountMagicLink, SubscriptionData } from '../models';
import { AccountData, PartialAccountData } from '../models/account-data';
import { AccountLogData } from '../models/account-log-data';
import { AccountUsersData } from '../models/account-users-data';
import { UiModalService } from './../components/modal/ui-modal.service';
import { CreateMagicLinkResponse } from './../models/account-magic-link';
import { AccountSubscriptionUsersData } from './../models/account-subscription-users-data';
import { LookerGroup } from './../models/looker-group';
import { UserData } from './../models/user-data';
import { GlobalsService } from 'src/app/core/services/globals.service';

interface DataMessageResponse {
    message: string;
    accountId?: string;
}

const Accounts_API = 'accounts';

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    umsUserId: string;

    constructor(private dataApiService: DataApiService,
        private uiModalService: UiModalService,
        private globalService: GlobalsService) {

            this.umsUserId = `?umsUserId=${this.globalService.userid}`
        }

    getSubscriptionTypes(): any[] {
        return [
            { label: 'Retail Insight - Basic', product: 'Retail Insight', value: 'Basic' },
            { label: 'Retail Insight - Essentials', product: 'Retail Insight', value: 'Essentials' },
            { label: 'Retail Insight - Advanced', product: 'Retail Insight', value: 'Advanced' },
            { label: 'Retail Insight - Trial', product: 'Retail Insight', value: 'Trial' },
            { label: 'Digital Commerce Radar - Basic', product: 'Digital Commerce Radar', value: 'Basic' },
            { label: 'Retail Insight - Custom', product: 'Retail Insight', value: 'Custom' },
        ]
    }

    getLookerGroups(): LookerGroup[] {
        return [
            {
                lookerGroupId: 357,
                lookerGroupName: 'Enable Data Downloads (Applies only for Trial accounts)'
            }
        ]
    }

    getAccounts(): Observable<AccountData[]> {
        return this.dataApiService.getData<AccountData[]>(`${Accounts_API}`);
    }

    getUsersInAccount(accountId: string, paginationToken?: string): Observable<AccountUsersData> {
        const endResourceApi =`${Accounts_API}/${accountId}/users`;
        return this.dataApiService.postData(endResourceApi, {paginationToken: paginationToken})
            .pipe(
                map((data: AccountUsersData) => {
                    data.users = data.users.map(user => {
                        user.userStatus === 'EXTERNAL_PROVIDER'
                            ? 'SSO USER'
                            : user.userStatus;
                        return user;
                    });
                    return data;
                })
            );
    }

    getAccountLogs(id: string): Observable<AccountLogData> {
        return this.dataApiService.getData(`${Accounts_API}/${id}/logs`);
    }

    getAccountbyId(id: string): Observable<AccountData> {
        return this.dataApiService.getData(`${Accounts_API}/${id}${this.umsUserId}`);
    }

    createAccount(account: PartialAccountData): Observable<DataMessageResponse> {
        return this.dataApiService.postData(`${Accounts_API}`, account);
    }

    updateAccount(accId: string, account: PartialAccountData): Observable<any> {
        return this.dataApiService.patchData(`${Accounts_API}/${accId}`, account);
    }

    addDomain(accountId: string, domain: string): Observable<DataMessageResponse> {
        const endResourceApi =`${Accounts_API}/${accountId}/domains/${domain}`;
        return this.dataApiService.postData(endResourceApi, {});
    }

    removeDomain(accountId: string, domain: string): Observable<any> {
        const endResourceApi =`${Accounts_API}/${accountId}/domains/${domain}`;
        return this.dataApiService.deleteData(endResourceApi, {});
    }

    search(searchTerm: string): Observable<AccountData[]> {
        if (!searchTerm) {
            return of([]);
        }

        const resEndPoint = `${Accounts_API}?accountname=${searchTerm}`;
        return this.dataApiService.getData<AccountData[]>(resEndPoint);
    }

    getSubscriptionUsers(accId: string, subsId: string, lastEvaluatedKey?: {PK: string; SK: string}):
        Observable<AccountSubscriptionUsersData> {
        const url = `${Accounts_API}/${accId}/subscriptions/${subsId}/users`;
        const reqBody = !lastEvaluatedKey ? {"pageLimit": 50} : {"pageLimit": 50, "lastEvaluatedKey": lastEvaluatedKey};
        return this.dataApiService.postData(url, reqBody);
    }

    searchSubcriptionUsers(accountId: string, subId: string, searchTerm: string): Observable<UserData[]> {
        if (!searchTerm) {
            return of([]);
        }

        const url = `${Accounts_API}/${accountId}/subscriptions/${subId}/users?email=${searchTerm}`;
        return this.dataApiService.getData<UserData[]>(url);
    }

    addUserToSubscription(accId: string, subscriptionId: string, userId: string): Observable<DataMessageResponse> {
        const url = `${Accounts_API}/${accId}/subscriptions/${subscriptionId}/users/${userId}`;
        return this.dataApiService.postData(url, {});
    }

    removeUserFromSubscription(accId: string, subscriptionId: string, userId: string) {
        const url = `${Accounts_API}/${accId}/subscriptions/${subscriptionId}/users/${userId}`;
        return this.dataApiService.deleteData(url);
    }

    enableAccount(accountId: string): Observable<DataMessageResponse> {
        return this.dataApiService.patchData(`${Accounts_API}/${accountId}/status`, {status: true});
    }

    disableAccount(accountId: string): Observable<DataMessageResponse> {
        return this.dataApiService.patchData(`${Accounts_API}/${accountId}/status`, {status: false});
    }

    searchAccountUsers(accId: string, searchTerm: string): Observable<UserData[]> {
        if (!searchTerm) {
            return of([]);
        }

        const url = `${Accounts_API}/${accId}/users?email=${searchTerm}`;
        return this.dataApiService.getData<UserData[]>(url);
    }

    deleteMagicLink(accId: string, magicLinkId: string) {
        const url = `${Accounts_API}/${accId}/magiclink/${magicLinkId}`;
        return this.dataApiService.deleteData<DataMessageResponse>(url);
    }

    createMagicLink(accId: string, subscriptions: string[]):Observable<CreateMagicLinkResponse> {
        const url = `${Accounts_API}/${accId}/magiclink`;
        return this.dataApiService.postData(url, {subscriptions: subscriptions});
    }

    getMagicLink(accId: string, magicLinkId: string): Observable<AccountMagicLink> {
        const url = `${Accounts_API}/${accId}/magiclink/${magicLinkId}`;
        return this.dataApiService.getData(url);
    }

    openAddEditAccountModal(content: any, account?: AccountData): NgbModalRef {
        const modalRef = this.uiModalService.openModal(content, { size: 'lg' });
        modalRef.componentInstance.account = account;
        return modalRef;
    }

    openAddSubscriptionModal(content: any, acc: AccountData): NgbModalRef {
        const modalRef = this.uiModalService.openModal(content, { size: 'lg' });
        modalRef.componentInstance.accountId = acc.accountId;
        modalRef.componentInstance.isAddSubscription = true;
        return modalRef;
    }

    addSubscription(accId: string, subscription: any) {
        const url = `${Accounts_API}/${accId}/subscriptions`;
        return this.dataApiService.postData(url, subscription);
    }

    updateSubscription(accId: string, subId: string, subscription: any) {
        const url = `${Accounts_API}/${accId}/subscriptions/${subId}`;
        return this.dataApiService.patchData(url, subscription);
    }

    updateSubsStatus(accId: string, subId: string, status: {status: boolean}) {
        const url = `${Accounts_API}/${accId}/subscriptions/${subId}/status`;
        return this.dataApiService.patchData(url, status);
    }

    getSubscription(accId: string, subsId: string): Observable<SubscriptionData> {
        const url = `${Accounts_API}/${accId}/subscriptions/${subsId}`;
        return this.dataApiService.getData(url);
    }

    configureSubscription(accId: string, subsId: string, group: {key: string, value: string | number})
        : Observable<DataMessageResponse> {
        const url = `${Accounts_API}/${accId}/subscriptions/${subsId}/configure`;
        return this.dataApiService.patchData(url, group);
    }

    deleteSubscription(accId: string, subId: string) {
        const url = `${Accounts_API}/${accId}/subscriptions/${subId}`;
        return this.dataApiService.deleteData<DataMessageResponse>(url);
    }
}
